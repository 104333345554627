// Global - Config
import { HTTP } from 'config'
// API - Response
import { IAPIHomeResponse } from './apiResponse'

export async function APIHome(): Promise<IAPIHomeResponse> {
  let apiResponse = {} as IAPIHomeResponse
  await HTTP.post('home').then((res) => {
    if (res.status === 200 && res.data) {
      apiResponse = res.data
    }
  })

  return apiResponse
}
