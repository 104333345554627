// Global - Config
import { HTTP } from 'config'
// API - Response
import { IAPIActivityResponse } from './apiResponse'

export async function APIActivity(): Promise<IAPIActivityResponse> {
  let apiResponse = {} as IAPIActivityResponse
  await HTTP.post('activity').then((res) => {
    if (res.status === 200 && res.data) {
      apiResponse = res.data
    }
  })

  return apiResponse
}
