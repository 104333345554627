// MainStem - UI/UX
import {
  Background,
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  FormattedCurrency,
  FormattedNumber,
  Loader,
  Tooltip
} from '@mainstem/react-mainstem'
// Icons
import { faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Local - Types
import { ICardColor } from './types'

/***
 * CardColor is a component that displays a card with a background image, a (styled) number, title, tooltip and an action button.
 */
const CardColor: React.FC<ICardColor> = ({ background, isCurrency, loading, number, title, tooltip }: ICardColor) => {
  return (
    <>
      <Card disableCollapse>
        <CardHeader
          title={
            <CardHeaderTitle>
              <Tooltip content={tooltip}>
                <span>
                  {title}:&nbsp;
                  {tooltip && (
                    <FontAwesomeIcon
                      className='text-400'
                      icon={faQuestionCircle}
                      id='draftsTooltip'
                      transform='shrink-1'
                    />
                  )}
                </span>
              </Tooltip>
            </CardHeaderTitle>
          }
        />
        <CardBody>
          <div className='border'>
            <Background image={background} />
          </div>
          <br />
          <h4 className='font-weight-normal text-sans-serif text-700 line-height-1 mb-1 lg-card-header'>
            {loading ? (
              <Loader color='light' containerHeight={50} type='grow' />
            ) : isCurrency ? (
              <FormattedCurrency value={number} />
            ) : (
              <FormattedNumber value={number} />
            )}
          </h4>
        </CardBody>
      </Card>
    </>
  )
}

export { CardColor }
