// ECharts
import ReactECharts from 'echarts-for-react'
// Local - Options
import { options } from './options'
// Local - Types
import { IChartPie } from './types'
import { formattedCurrencyAsString } from '@mainstem/react-mainstem'

const ChartPie: React.FC<IChartPie> = ({ currency, data, title }: IChartPie) => {
  const optionsToUse = { ...options(currency) }

  optionsToUse.title = {
    text: title,
    left: 'center',
    fontFamily: 'Poppins'
  }

  optionsToUse.series[0].data = data.map((item) => {
    return {
      value: item.data,
      name: `${item.label} - ${currency ? formattedCurrencyAsString(item.data) : item.data}`
    }
  })
  // optionsToUse.series[0].color = ['#440099', '#00B5E2']

  return (
    <>
      <ReactECharts option={optionsToUse} />
    </>
  )
}

export { ChartPie }
