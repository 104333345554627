import { formattedCurrencyAsString } from '@mainstem/react-mainstem'
import { EChartsOption } from 'echarts-for-react'

export const options = (currency) => {
  return {
    legend: {
      orient: 'vertical',
      left: 'left'
    },
    xAxis: {
      type: 'category',
      data: []
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        fontFamily: 'Poppins',
        formatter: function (value: any) {
          return `${currency ? formattedCurrencyAsString(value) : value}`
        },
        margin: -35
      }
    },
    series: [],
    tooltip: {
      trigger: 'axis'
    }
  } as EChartsOption
}

export const defaultSeries = (currency) => {
  return {
    data: [],
    type: 'bar',
    smooth: true,
    itemStyle: {
      normal: {
        label: {
          show: true,
          position: 'top',
          fontFamily: 'Poppins',
          color: '#222222',
          formatter: function (value: any) {
            return `${currency ? formattedCurrencyAsString(value.data) : value.data}`
          }
        }
      }
    }
  }
}
