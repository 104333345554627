// React
import ReactDOM from 'react-dom/client'
// Global - Assets - CSS
import 'assets/css/root.css'
// Global - Routes
import AppRoutes from 'routes'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<AppRoutes />)
