import { CalendarIcon } from '@heroicons/react/24/outline'
import { PageTitle } from '@mainstem/react-mainstem'

const PageAppCalendar: React.FC = () => {
  return (
    <>
      <PageTitle
        actions={<></>}
        subtitle={`View important events in MainStem's timeline.`}
        title={
          <>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <div>
                <CalendarIcon className='mr-2 h-8 w-8' />
              </div>
              <div>MainStem Calendar</div>
            </div>
          </>
        }
      />
      <br />
      Calendar Content Goes Here!
    </>
  )
}

export { PageAppCalendar }
