// React
import { useEffect } from 'react'
// Routing
import { useNavigate } from 'react-router-dom'
// Local - Types
import { IRedirect } from './types'

const Redirect: React.FC<IRedirect> = ({ to }: IRedirect) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  }, [])
  return <></>
}

export { Redirect }
