import { UserGroupIcon } from '@heroicons/react/24/outline'
import { PageTitle } from '@mainstem/react-mainstem'

const PageAppTeam: React.FC = () => {
  return (
    <>
      <PageTitle
        actions={<></>}
        subtitle={`See the amazing people behind the company.`}
        title={
          <>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <div>
                <UserGroupIcon className='mr-2 h-8 w-8' />
              </div>
              <div>MainStem Team</div>
            </div>
          </>
        }
      />
      <br />
      Team Content Here
    </>
  )
}

export { PageAppTeam }
