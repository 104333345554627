import axios from 'axios'

const config = {
  fontFamily: 'Poppins'
}

// Set Axios defaults
const HTTP = axios.create({
  baseURL: 'https://api.mainstem.io/api/insights'
  // headers: {
  //   Authorization: `Bearer ${enterpriseToken || ''}`,
  //   'X-AffiliateProgramUUID': affiliateProgramUUID,
  //   'X-SessionURL': sessionURL,
  //   'X-Platform': 'Purchase Pro'
  // }
})

export { config, HTTP }
