import { theme } from '@mainstem/react-mainstem'
import { Outlet } from 'react-router-dom'

const LayoutAuth: React.FC = () => {
  return (
    <>
      <div className='flex min-h-full'>
        <div className='flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
          <div className='mx-auto w-full max-w-sm lg:w-96'>
            <Outlet />
          </div>
        </div>
        <div className='relative hidden w-0 flex-1 lg:block'>
        <img
              className='absolute inset-0 h-full w-full object-cover login-background-image'
              src='https://images.unsplash.com/photo-1622839497468-9e4a20007cf8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1650&q=80'
              alt=''
            />
          <div className='login-background-image-wrapper'>
          
          </div>
        </div>
      </div>
    </>
  )
}

export { LayoutAuth }
