// React
import { useState } from 'react'
// MainStem - UI
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  FormattedCurrency,
  FormattedNumber,
  Grid,
  GridItem,
  Loader,
  PageTitle,
  useOnFirstLoad
} from '@mainstem/react-mainstem'
// MainStem - API
import { APIHome } from 'api/home'
import { IAPIHomeResponse } from 'api/home/apiResponse'
import { CardColor } from 'components'

import imgInc5000 from 'assets/img/inc5000.png'
import imgMicrosoft from 'assets/img/microsoft.png'
import imgSoc2 from 'assets/img/soc2.png'

const PageAppHome: React.FC = () => {
  const [stats, setStats] = useState<IAPIHomeResponse | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)

  const loadStats = () => {
    setLoading(true)
    APIHome()
      .then((apiResponse) => {
        setStats(apiResponse)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadStats()
  })

  return (
    <>
      <PageTitle
        actions={
          <>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <img src={imgMicrosoft} style={{ height: 40 }} />
              <img src={imgInc5000} style={{ height: 50, marginLeft: 25 }} />
              <img src={imgSoc2} style={{ height: 50, marginLeft: 25 }} />
            </div>
          </>
        }
        subtitle='Welcome to MainStem Insights™.  Get a birds eye view of the company and its performance, or drill down deep for account level details.'
        title='MainStem Insights'
      />
      <br />
      {loading ? (
        <Loader />
      ) : (
        <>
          {stats ? (
            <>
              <Grid cols={3}>
                <GridItem colSpan={1}>
                  <CardColor
                    background={'info'}
                    isCurrency={true}
                    number={stats.totalGMV}
                    title={'Gross Merchandise Value'}
                    tooltip={'Total transactional volume processed on the MainStem platform.'}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <CardColor
                    background={'success'}
                    isCurrency={true}
                    number={stats.totalRevenue}
                    title={'Converted Revenue'}
                    tooltip={'Total amount of GMV MainStem earned a commission on.'}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <CardColor
                    background={'success'}
                    isCurrency={true}
                    number={stats.totalPayments}
                    title={'Payments'}
                    tooltip={'Total amount of payments processed on MainStem.'}
                  />
                </GridItem>
              </Grid>
              <br />
              <Grid cols={4}>
                <GridItem colSpan={1}>
                  <CardColor
                    background={'success'}
                    number={stats.totalCustomers}
                    title={'Customers'}
                    tooltip={'Total number of customer accounts created over the lifetime of MainStem'}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <CardColor
                    background={'success'}
                    number={stats.totalLocations}
                    title={'Customer Locations'}
                    tooltip={'Total number of customer locations created over the lifetime of MainStem'}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <CardColor
                    background={'info'}
                    number={stats.totalSuppliers}
                    title={'Suppliers'}
                    tooltip={'Total number of supplier accounts created over the lifetime of MainStem'}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <CardColor
                    background={'danger'}
                    number={stats.totalUsers}
                    title={'Users'}
                    tooltip={'Total number of user accounts created over the lifetime of MainStem'}
                  />
                </GridItem>
              </Grid>
              <br />
              <Grid cols={2}>
                <GridItem colSpan={1}>
                  <Card>
                    <CardHeader title={<CardHeaderTitle>MTD - Approved</CardHeaderTitle>} />
                    <CardBody>
                      <FormattedCurrency value={stats.mtdApproved} />
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem colSpan={1}>
                  <Card>
                    <CardHeader title={<CardHeaderTitle>MTD - Shipped</CardHeaderTitle>} />
                    <CardBody>
                      <FormattedCurrency value={stats.mtdShipped} />
                    </CardBody>
                  </Card>
                </GridItem>
              </Grid>
              <br />
              <Grid cols={2}>
                <GridItem colSpan={1}>
                  <Card>
                    <CardHeader title={<CardHeaderTitle>YTD - Approved</CardHeaderTitle>} />
                    <CardBody>
                      <FormattedCurrency value={stats.ytdApproved} />
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem colSpan={1}>
                  <Card>
                    <CardHeader title={<CardHeaderTitle>YTD - Shipped</CardHeaderTitle>} />
                    <CardBody>
                      <FormattedCurrency value={stats.ytdShipped} />
                    </CardBody>
                  </Card>
                </GridItem>
              </Grid>
              <br />
              <Grid cols={2}>
                <GridItem colSpan={1}>
                  <Card>
                    <CardHeader title={<CardHeaderTitle>Total Shipments</CardHeaderTitle>} />
                    <CardBody>
                      <FormattedNumber value={stats.totalShipments} />
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem colSpan={1}>
                  <Card>
                    <CardHeader title={<CardHeaderTitle>Total Lbs Shipped</CardHeaderTitle>} />
                    <CardBody>
                      <FormattedNumber decimalPlaces={2} value={stats.totalLBSShipped} />
                      &nbsp;lbs
                    </CardBody>
                  </Card>
                </GridItem>
              </Grid>
            </>
          ) : (
            <>No Stats Loaded</>
          )}
        </>
      )}
    </>
  )
}

export { PageAppHome }
