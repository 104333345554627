import { ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import { PageTitle } from '@mainstem/react-mainstem'

const PageAppFiles: React.FC = () => {
  return (
    <>
      <PageTitle
        actions={<></>}
        subtitle='Easily access, view, and download the investor files.'
        title={
          <>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <div>
                <ClipboardDocumentIcon className='mr-2 h-8 w-8' />
              </div>
              <div>MainStem Investor Files</div>
            </div>
          </>
        }
      />
      <br />
      Files Content Here
      <hr />
      <br />
      Download The Complete Pack (.zip) which includes:
      <br />
      <br />
      <ul className='list-disc'>
        <li>Pitch Deck</li>
        <li>Financials</li>
        <li>Cap Table</li>
        <li>Business Plan</li>
      </ul>
    </>
  )
}

export { PageAppFiles }
