import { formattedCurrencyAsString } from '@mainstem/react-mainstem'
import { EChartsOption } from 'echarts-for-react'

export const options = (currency) => {
  return {
    title: {
      text: 'Referer of a Website',
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: '50%',
        color: ['#00B5E2', '#440099'],
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  } as EChartsOption
}
