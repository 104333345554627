import { MapIcon } from '@heroicons/react/24/outline'
import { PageTitle } from '@mainstem/react-mainstem'

const PageAppRoadmap: React.FC = () => {
  return (
    <>
      <PageTitle
        actions={<></>}
        subtitle={`View real-time information about MainStem's Propduct Roadmap.`}
        title={
          <>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <div>
                <MapIcon className='mr-2 h-8 w-8' />
              </div>
              <div>MainStem Roadmap</div>
            </div>
          </>
        }
      />
      <br />
      <div style={{ height: 'calc(80vh)' }}>
        <iframe
          src='https://roadmap.productboard.com/80c023a5-e34b-451a-95bb-40c2de70b53a'
          width='100%'
          height='100%'
        ></iframe>
      </div>
    </>
  )
}

export { PageAppRoadmap }
