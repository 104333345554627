// React
import { useState } from 'react'
// MainStme - UI
import { FormattedDateTime, Loader, theme, useOnFirstLoad } from '@mainstem/react-mainstem'
// Icons
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid'
// API
import { APIActivity } from 'api/activity'
import { IAPIActivityResponseActivityDetails } from 'api/activity/apiResponse'

const Feed: React.FC = () => {
  const [activity, setActivity] = useState<IAPIActivityResponseActivityDetails[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const loadData = () => {
    setLoading(true)
    APIActivity()
      .then((apiResponse) => {
        setActivity(apiResponse.activities)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadData()
    setInterval(loadData, 30000)
  })

  return (
    <>
      <h2 className='font-semibold text-gray-900 mb-8 text-center flex' style={{ alignItems: 'center' }}>
        <div style={{ paddingRight: 10, width: 25 }}>{loading && <Loader containerHeight={15} size='sm' />}</div>
        <div>MainStem Activity</div>
      </h2>
      <div className='flow-root'>
        <ul role='list' className='-mb-8'>
          {activity.map((activityItem, activityItemIdx) => (
            <li key={activityItem.date.toString()} style={{ animation: `fadeIn ${500 * activityItemIdx}ms` }}>
              <div className='relative pb-8'>
                {activityItemIdx !== activity.length - 1 ? (
                  <span className='absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200' aria-hidden='true' />
                ) : null}
                <div className='relative flex items-start space-x-3'>
                  <div className='relative'>
                    <img
                      className='flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white'
                      src={activityItem.image || theme.images.notAvailable}
                      alt=''
                    />
                    <span className='absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px'>
                      <ChatBubbleLeftEllipsisIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                    </span>
                  </div>
                  <div className='min-w-0 flex-1'>
                    <div>
                      <div className='text-sm'>
                        <span className='font-medium text-gray-900'>{activityItem.title}</span>
                      </div>
                      <p className='mt-0.5 text-sm text-gray-500'>
                        <FormattedDateTime datetime={activityItem.date} />
                      </p>
                    </div>
                    <div className='mt-2 text-sm text-gray-700'>
                      <p>{activityItem.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export { Feed }
