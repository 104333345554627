// ECharts
import ReactECharts from 'echarts-for-react'
// Local - Options
import { defaultSeries, options } from './options'
// Local - Types
import { IChartBar } from './types'

const ChartBar: React.FC<IChartBar> = ({ color, color2, currency, data, data2, title }: IChartBar) => {
  const optionsToUse = { ...options(currency) }

  optionsToUse.title = {
    text: title,
    left: 'center',
    label: { fontFamily: 'Poppins' }
  }

  const xData = data.map((item) => item.label)
  optionsToUse.xAxis.data = xData

  const yData = data.map((item) => item.data)

  let series0 = { ...defaultSeries(currency) } as any

  series0.data = yData
  series0.itemStyle.normal.color = color

  if (data2) {
    const yData2 = data2.map((item) => item.data)

    series0.name = 'In Network'
    
    let series1 = { ...defaultSeries(currency) } as any

    series1.name = 'Private'

    series1.data = yData2
    series1.itemStyle.normal.color = color2

    optionsToUse.series = [series0, series1]
  } else {
    optionsToUse.series = [series0]
  }

  return (
    <>
      <br />
      <ReactECharts option={optionsToUse} />
    </>
  )
}

export { ChartBar }
