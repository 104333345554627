import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { CursorArrowRaysIcon, EnvelopeOpenIcon, GlobeAltIcon, UsersIcon } from '@heroicons/react/24/outline'
import { PageTitle } from '@mainstem/react-mainstem'
import { ChartLine, ChartPie } from 'components'
import { classNames } from 'helpers'

const stats = [
  { id: 1, name: 'Total Users', stat: '71,897', icon: UsersIcon, change: '122', changeType: 'increase' },
  { id: 2, name: 'Conversion', stat: '58.16%', icon: EnvelopeOpenIcon, change: '5.4%', changeType: 'increase' },
  {
    id: 3,
    name: 'Gross Merchandise Value',
    stat: '$2,432,234',
    icon: CursorArrowRaysIcon,
    change: '3.2%',
    changeType: 'decrease'
  }
]

const PageAppMarket: React.FC = () => {
  return (
    <>
      <PageTitle
        actions={
          <>
            <h3 className='text-base font-semibold leading-6 text-gray-900'>Last 30 days</h3>
          </>
        }
        subtitle={`View real-time information about MainStem's Market Coverage.`}
        title={
          <>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <div>
                <GlobeAltIcon className='mr-2 h-8 w-8' />
              </div>
              <div>MainStem Market Coverage</div>
            </div>
          </>
        }
      />
      <div>
        <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3'>
          {stats.map((item) => (
            <div
              key={item.id}
              className='relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6'
            >
              <dt>
                <div className='absolute rounded-md bg-indigo-500 p-3'>
                  <item.icon className='h-6 w-6 text-white' aria-hidden='true' />
                </div>
                <p className='ml-16 truncate text-sm font-medium text-gray-500'>{item.name}</p>
              </dt>
              <dd className='ml-16 flex items-baseline pb-6 sm:pb-7'>
                <p className='text-2xl font-semibold text-gray-900'>{item.stat}</p>
                <p
                  className={classNames(
                    item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                    'ml-2 flex items-baseline text-sm font-semibold'
                  )}
                >
                  {item.changeType === 'increase' ? (
                    <ArrowUpIcon className='h-5 w-5 flex-shrink-0 self-center text-green-500' aria-hidden='true' />
                  ) : (
                    <ArrowDownIcon className='h-5 w-5 flex-shrink-0 self-center text-red-500' aria-hidden='true' />
                  )}

                  <span className='sr-only'> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                  {item.change}
                </p>
                <div className='absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6'>
                  <div className='text-sm'>
                    <a href='#' className='font-medium text-indigo-600 hover:text-indigo-500'>
                      {' '}
                      View all<span className='sr-only'> {item.name} stats</span>
                    </a>
                  </div>
                </div>
              </dd>
            </div>
          ))}
        </dl>

        <div className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3'>
          <div className='relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6'>
            <ChartPie
              currency={false}
              data={[
                { name: 'Free', value: 100 },
                { name: 'Premium', value: 200 }
              ]}
              title='Account Types'
            />
          </div>
          <div className='relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6'>
            <ChartLine
              labelX='Account Type'
              labelY='Total'
              data={[
                { name: 'Free', value: 100 },
                { name: 'Premium', value: 200 }
              ]}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export { PageAppMarket }
