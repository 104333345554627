import { CpuChipIcon } from '@heroicons/react/24/outline'
import { PageTitle } from '@mainstem/react-mainstem'
import imgTechStack from 'assets/img/MainStem-Tech-Stack.png'

const PageAppTechnology: React.FC = () => {
  return (
    <>
      <PageTitle
        actions={<></>}
        subtitle={`Learn about the MainStem platform and the technology we've created.`}
        title={
          <>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <div>
                <CpuChipIcon className='mr-2 h-8 w-8' />
              </div>
              <div>MainStem Tech Stack</div>
            </div>
          </>
        }
      />
      <br />
      <div className='flex justify-center'>
        <img src={imgTechStack} style={{ maxWidth: '100%' }} />
      </div>
    </>
  )
}

export { PageAppTechnology }
