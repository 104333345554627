// ECharts
import ReactECharts from 'echarts-for-react'
// Local - Options
import { options } from './options'
// Local - Types
import { IChartLine } from './types'

const ChartLine: React.FC<IChartLine> = ({ data, labelX, labelY }: IChartLine) => {
  return (
    <>
      <ReactECharts option={options} />
    </>
  )
}

export { ChartLine }
