// Global - Config
import { HTTP } from 'config'
// API - Request
import { IAPIGoalsRequest } from './apiRequest'
// API - Response
import { IAPIGoalsResponse } from './apiResponse'

export async function APIGoals(apiRequest: IAPIGoalsRequest): Promise<IAPIGoalsResponse> {
  let apiResponse = {} as IAPIGoalsResponse
  await HTTP.post('goals', apiRequest).then((res) => {
    if (res.status === 200 && res.data) {
      apiResponse = res.data
    }
  })

  return apiResponse
}
