import { MegaphoneIcon } from '@heroicons/react/24/outline'
import { PageTitle } from '@mainstem/react-mainstem'

const PageAppAnnouncements: React.FC = () => {
  return (
    <>
      <PageTitle
        actions={<></>}
        subtitle='View all of the recent announcements the company has made.  Click on an announcement to view the details.'
        title={
          <>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <div>
                <MegaphoneIcon className='mr-2 h-8 w-8' />
              </div>
              <div>MainStem Announcements</div>
            </div>
          </>
        }
      />
      <br />
      Announcements Content Here
      <hr />
      <br />
      <ul className='list-disc'>
        <li>v 1.6.0 Released</li>
        <li>Q1 2023 - Shareholder Report Issued</li>
        <li>v 1.5.7 Released</li>
      </ul>
    </>
  )
}

export { PageAppAnnouncements }
